@import 'theme/colors.scss';

input,
select,
textarea {
  padding: 0.5rem 0.75rem;
  border: 0.0625rem solid $darkerGrey;
  background-color: $white;
  border-radius: 0.35rem;
  color: $darkGrey;
  font-size: 0.95rem;
  height: 3rem;
  width: 100%;
  margin-bottom: 1rem;
  transition: border 0.2s ease;
}

textarea {
  min-height: 7rem;
  font: 0.9375rem/1.5 'Open Sans', Helvetica, Arial, 'sans serif';
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select:hover {
  cursor: pointer;
}

select > option {
  background-color: $white;
}

.customArrow {
  position: relative;
  top: -2.2rem;
  right: 0.75rem;
  transition: transform 0.2s ease;
}

select:focus ~ .customArrow {
  transform: rotate(-90deg);
}

input:disabled,
select:disabled,
textarea:disabled {
  background-color: $lightGrey;
  border: none;
  cursor: not-allowed;
}

input::placeholder,
textarea::placeholder {
  color: $grey;
}

input:focus,
select:focus {
  outline: none;
  border: 0.125rem solid $brand;
  box-shadow: 0 0 0.15rem $brand;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

input[type=checkbox] {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
}

input[type=checkbox]:hover {
  cursor: pointer;
}

.actionConfirmed {
  border: 0.125rem solid $green;
  box-shadow: 0 0 0.15rem $green;
}

.inlineGroup {
  display: inline-flex;
  width: 100%;
}

.inlineGroup li {
  margin: 0 0.5rem;
  flex-grow: 1;
  flex-shrink: 1;
}

.inlineGroup li:first-child {
  margin-left: 0;
}

.inlineGroup li:last-child {
  margin-right: 0;
}

.fieldWithAction {
  display: inline-flex;
  width: 100%;
}

.fieldWithAction input {
  padding-right: 3rem;
}

.fieldWithAction button {
  position: relative;
  top: 0.1rem;
  margin-left: -3rem;
  width: 2.9rem !important;
  height: 2.8rem;
}

.legal,
.trust {
  margin-bottom: 1rem;
}

.trust {
  margin: 1rem auto;
}

.legal input,
.trust input {
  position: relative;
  top: 0.35rem;
  margin-right: 0.5rem !important;
}

.legal .formFeedback,
.trust .formFeedback {
  text-align: center;
  margin: 0.5rem 0 0;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 768px) {
  .inlineGroup {
    flex-direction: column;
  }

  .inlineGroup li {
    margin: 0;
  }
}