@import 'theme/colors.scss';

main {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr 25rem;
  height: 100%;
  max-width: 120rem;
  margin: 0 auto;
  background-color: $offWhite;
  grid-template-areas:
    "content panel"
    "content panel"
    "content footer";

  #content {
    grid-area: content;
    padding: 1rem;
    overflow-y: auto;
  }

  #panel {
    display: grid;
    grid-area: panel;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "about"
      "workflow";

    position: relative;
    z-index: 1;
    width: 100%;
    background-color: #3a3a3a;
    color: $offWhite;
    box-shadow: 0 -0.15rem 0.2rem $black;

    i {
      cursor: pointer;
    }

    a {
      color: $offWhite !important;
      text-decoration: none;
    }

    ul {
      display: inline-flex;

      li {
        margin: 0 0.25rem;
      }
    }

  }

  footer {
    grid-area: footer;
    padding: 0 1rem;
    color: $offWhite;
    background-color: $darkGrey2;
    box-shadow: 0 -0.15rem 0.2rem $black;

    #other {
      width: 100%;
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr auto;
      align-items: center;
      justify-content: space-between;
    }
  }
}


/* ========== Media Queries ========== */
@media only screen and (max-height: 788px) {
  main {
    height: unset;

    #panel {
      height: 100%;
    }
  }
}

@media only screen and (max-width: 968px) {
  main {
    height: unset;
    width: fit-content;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "panel"
      "content"
      "footer";

    #content {
      overflow-y: unset;
    }

    footer {
      box-shadow: unset;
    }
  }
}