@import 'theme/colors.scss';

#about {
  display: grid;
  gap: 0.5rem;
  grid-area: about;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "title"
    "description";

  width: 100%;
  padding: 1rem;
  font-size: 0.9rem;

  .title {
    display: grid;
    align-items: center;
    gap: 0.5rem;
    grid-template-columns: auto 1fr;
    grid-area: title;

    img {
      width: 5.3rem;
      height: auto;
      border-radius: 50%;
    }

    .social {
      margin-top: 0.5rem;

      a {
        margin: 0 0.35rem;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        i {
          font-size: 1.3rem;
        }
      }
    }
  }

  .description {
    grid-area: description;
  }
}



/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}