$white: white;
$black: #141414;
$offWhite: #f5f5f5;
$darkerGrey: #282828;
$darkerGrey2: #1f1f1f;
$darkGrey2: #3a3a3a;
$darkGrey: #707070;
$grey: #909090;
$lightGrey: #cdcdcd;
$lighterGrey: #ebebeb;
$violet: #9d4eb9;
$purple: #775982;
$pastelPurple: #ae9ad8;
$skyBlue: #1d8edf;
$blue: #4aa7e9;
$darkBlue: #406a8a;
$pastelBlue: #7fBfe4;
$cyan: #6dbfc2;
$pastelCyan: #7fd8e4;
$brand: #0caeca;
$green: #558371;
$pastelGreen: #7aD5b1;
$darkYellow: #473110;
$yellow: #fcb54c;
$pastelYellow: #df9c38;
$orange: #97624b;
$red: #a55353;
$slate: #616d74;
$darkerSlate: #2c3033;
$brown: #6d5c5a;
$transparent: transparent;

:export {
  white: $white;
  black: $black;
  offWhite: $offWhite;
  darkerGrey: $darkerGrey;
  darkerGrey2: $darkerGrey2;
  darkGrey: $darkGrey;
  darkGrey2: $darkGrey2;
  grey: $grey;
  lightGrey: $lightGrey;
  lighterGrey: $lighterGrey;
  violet: $violet;
  purple: $purple;
  pastelPurple: $pastelPurple;
  skyBlue: $skyBlue;
  blue: $blue;
  darkBlue: $darkBlue;
  pastelBlue: $pastelBlue;
  cyan: $cyan;
  pastelCyan: $pastelCyan;
  brand: $brand;
  green: $green;
  pastelGreen: $pastelGreen;
  darkYellow: $darkYellow;
  yellow: $yellow;
  pastelYellow: $pastelYellow;
  orange: $orange;
  red: $red;
  slate: $slate;
  darkerSlate: $darkerSlate;
  brown: $brown;
  transparent: $transparent;
}