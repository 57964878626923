@import 'theme/colors';

.tooltip {
  .tip {
    position: fixed;
    z-index: 1;
    background-color: $darkerGrey2;
    padding: 0.5rem;
    border-radius: 0.35rem;
    color: $offWhite;
    box-shadow: 0 0.1rem 0.25rem #00000080;

    i {
      color: $offWhite;
    }
  }

  i {
    font-size: 1rem;
    color: $grey;
  }
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}