@import './colors.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

ul {
  padding: 0 1rem;
  margin: 0;
}

sup {
  font-size: 0.5rem;
}

.strong {
  font-weight: 700;
}

.info,
.warning,
.error {
  position: relative;
  top: 0.2rem;
  left: 0.25rem;
  margin-right: 0.75rem;
}

.info::before,
.warning::before,
.error::before {
  font-size: 1.25rem;
}

.footnote {
  font-size: 0.72rem;
  color: $grey;
  font-weight: 600;
}

.scrollbar::-webkit-scrollbar {
  min-height: 18.75rem;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 0.625rem;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 0.35rem;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar {
  border-radius: 0.35rem;
  width: 0.625rem;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.35rem;
  background-color: $darkGrey;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  border-radius: 0.35rem;
  background-color: $darkerGrey;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1786px) {
  .footnote {
    font-size: 0.77rem;
  }
}

@media only screen and (max-width: 1500px) {
  .footnote {
    font-size: 0.79rem;
  }
}