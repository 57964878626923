@import 'theme/colors';

#projectContainer {
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5rem;

  #showcase {
    height: 100%;
    font-size: 0.9rem;

    .preview {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      perspective: 85rem;
      min-height: 10rem;
      color: $grey;
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      border-radius: 0.25rem;
      background: $darkGrey2;
      color: $offWhite;
      box-shadow: 0 0.1rem 0.2rem $grey;
      width: 25rem;
      min-width: 25rem;
      min-height: 40rem;

      .labelContainer {
        text-align: right;
        width: 100%;
        min-height: 1.76rem;
      }

      .appInfo {
        display: inline-grid;
        gap: 1rem;
        grid-template-columns: 1fr auto;
        align-items: center;
        margin-bottom: 1rem;

        .credentials {
          padding-left: 1rem;
          border-left: 0.0625rem solid $darkerGrey;
        }
      }

      .links {
        .placeholder {
          height: 3rem;
          width: 100%;
          margin-top: 0.4rem;
        }

        button {
          font-weight: bold;
          width: 100%;
          margin: 0.25rem 0;
          user-select: none;

          @media (hover: hover) {
            &:hover {
              filter: brightness(1.2)
            }
          }
        }
      }

    }
  }
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 1673px) {
  #projectContainer {
    #showcase {
      flex-grow: 1;

      .details {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  #projectContainer {
    #showcase {
      .details {
        .appInfo {
          gap: 0.75rem;

          .credentials {
            padding-left: 0.75rem;
          }
        }
      }
    }
  }
}