@import 'theme/colors.scss';
@import 'theme/common.scss';
@import 'theme/forms.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font: 0.9375rem/1.5 'Quicksand', Helvetica, Arial, 'sans serif';
  color: $darkGrey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $darkerGrey2;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:link,
a:visited,
a:active {
  color: $offWhite;
  font-weight: 700;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.fa-spin {
  font-size: 2rem;
}

/* ========== Media Queries ========== */
@media only screen and (max-width: 414px) {

}