@import 'theme/colors.scss';

.label {
  display: inline-flex;
  align-items: center;
  border-radius: 0.35rem;
  background-color: $darkerGrey2;
  color: $offWhite;
  font-size: 0.85rem;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  user-select: none;
  width: auto;

  i {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1rem;
    cursor: pointer;

    &.labelClose {
      margin-left: 0.5rem;
    }

    &::before {
      display: flex;
      align-items: center;
    }
  }
}

/* ========== Media Queries ========== */
@media only screen and (max-height: 414px) {

}